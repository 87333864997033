import React, { useState, useCallback } from "react";
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import numeral from 'numeral'
import _ from "lodash"
import useAxios from "axios-hooks"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import ls from 'local-storage'
import ImageViewer from "react-simple-image-viewer";

export default function Ships(props) {

    // Mint is the key.
    let mint = props.pageContext.mint

    // Capture real-time data on page load.
    const [{ data, loading, error }] = useAxios(
      "https://galaxy.staratlas.com/nfts/"
    )

    const theme = ls.get('theme')

    let ship = []
    let msrp;
    let shipClass;
    let shipDimensions;
    let shipRarity;
    let shipSpec;
    let componentSlots = []
    let crewSlots = []
    let moduleSlots = []
    let shipGallery = []
    if (data) {
        data.forEach(e => {
            if (e.mint === mint) {
                ship = e
                msrp = ship.tradeSettings.msrp.value
                shipClass = ship.attributes.class
                shipDimensions = `${ship.attributes.unitLength} x ${ship.attributes.unitWidth} x ${ship.attributes.unitHeight}`
                shipRarity = ship.attributes.rarity
                shipSpec = ship.attributes.spec
                componentSlots = ship.slots.componentSlots
                crewSlots = ship.slots.crewSlots
                moduleSlots = ship.slots.moduleSlots
                shipGallery = ship.media.gallery
            }
        })
    }

    // Lightbox
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = useCallback((index) => {
      setCurrentImage(index);
      setIsViewerOpen(true);
    }, []);
  
    const closeImageViewer = () => {
      setCurrentImage(0);
      setIsViewerOpen(false);
    };    

  return (
      <>
    <SEO title={ship.name} />
    <div className="bg-black ship-inner" data-theme={theme}>
      <div aria-hidden="true" className="relative">
        <img
          src={ship.image}
          alt={ship.name}
          className="w-full h-96 object-center object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black" />
      </div>
      <div className="relative -mt-12 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto text-center lg:max-w-4xl">
          <h1 className="text-3xl font-extrabold tracking-tight text-primary sm:text-4xl">
            {ship.name}
          </h1>
          <p className="mt-4 text-gray-500">{ship.description}</p>
        </div>
        <dl className="mt-16 max-w-2xl mx-auto grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:max-w-none lg:grid-cols-3 lg:gap-x-8">
            {/* MSRP */}
            <div className="border-t border-gray-200 pt-4">
              <dt className="font-medium text-primary">MSRP</dt>
              <dd className="mt-2 text-sm text-gray-500 capitalize">
                {numeral(msrp).format('$0, $0.00')}
              </dd>
            </div>
            {/* Symbol */}
            <div className="border-t border-gray-200 pt-4">
              <dt className="font-medium text-primary">Symbol</dt>
              <dd className="mt-2 text-sm text-gray-500 capitalize">
                {ship.symbol}
              </dd>
            </div>
            {/* Dimensions */}
            <div className="border-t border-gray-200 pt-4">
              <dt className="font-medium text-primary">Dimensions</dt>
              <dd className="mt-2 text-sm text-gray-500 capitalize">
                {shipDimensions}
              </dd>
            </div>
            {/* Rarity */}
            <div className="border-t border-gray-200 pt-4">
              <dt className="font-medium text-primary">Rarity</dt>
              <dd className="mt-2 text-sm text-gray-500 capitalize">
                {shipRarity}
              </dd>
            </div>
            {/* Class */}
            <div className="border-t border-gray-200 pt-4">
              <dt className="font-medium text-primary">Class</dt>
              <dd className="mt-2 text-sm text-gray-500 capitalize">
                {shipClass}
              </dd>
            </div>
            {/* Spec */}
            <div className="border-t border-gray-200 pt-4">
              <dt className="font-medium text-primary">Spec</dt>
              <dd className="mt-2 text-sm text-gray-500 capitalize">
                {shipSpec}
              </dd>
            </div>
            {/* Module Slots */}
            <div className="border-t border-gray-200 pt-4">
              <dt className="font-medium text-primary">Module Slots</dt>
              <dd className="mt-2 text-sm text-gray-500 capitalize">
                <table class="transparent-table table w-full">
                    <thead>
                        <tr>
                            <th>Type</th> 
                            <th>Size</th> 
                            <th>Qty</th> 
                        </tr>
                    </thead> 
                    <tbody>
                        {moduleSlots.map((slot, id) => (
                            <tr key={id}>
                                <td>
                                    <div className="mobile-only text-secondary">
                                        Type
                                    </div>
                                    {slot.type}
                                </td>
                                <td>
                                    <div className="mobile-only text-secondary">
                                        Size
                                    </div>
                                    {slot.size}
                                </td>
                                <td>
                                    <div className="mobile-only text-secondary">
                                        Qty
                                    </div>
                                    {slot.quantity}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
              </dd>
            </div>
            {/* Crew Slots */}
            <div className="border-t border-gray-200 pt-4">
              <dt className="font-medium text-primary">Crew Slots</dt>
              <dd className="mt-2 text-sm text-gray-500 capitalize">
                <table class="transparent-table table w-full">
                    <thead>
                        <tr>
                            <th>Type</th> 
                            <th>Size</th> 
                            <th>Qty</th> 
                        </tr>
                    </thead> 
                    <tbody>
                        {crewSlots.map((slot, id) => (
                            <tr key={id}>
                                <td>
                                    <div className="mobile-only text-secondary">
                                        Type
                                    </div>
                                    {slot.type}
                                </td>
                                <td>
                                    <div className="mobile-only text-secondary">
                                        Size
                                    </div>
                                    {slot.size}
                                </td>
                                <td>
                                    <div className="mobile-only text-secondary">
                                        Qty
                                    </div>
                                    {slot.quantity}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
              </dd>
            </div>                  
            {/* Component Slots */}
            <div className="border-t border-gray-200 pt-4">
              <dt className="font-medium text-primary">Component Slots</dt>
              <dd className="mt-2 text-sm text-gray-500 capitalize">
                <table class="transparent-table table w-full">
                    <thead>
                        <tr>
                            <th>Type</th> 
                            <th>Size</th> 
                            <th>Qty</th> 
                        </tr>
                    </thead> 
                    <tbody>
                        {componentSlots.map((slot, id) => (
                            <tr key={id}>
                                <td>
                                    <div className="mobile-only text-secondary">
                                        Type
                                    </div>
                                    {slot.type}
                                </td>
                                <td>
                                    <div className="mobile-only text-secondary">
                                        Size
                                    </div>
                                    {slot.size}
                                </td>
                                <td>
                                    <div className="mobile-only text-secondary">
                                        Qty
                                    </div>
                                    {slot.quantity}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
              </dd>
            </div>      
        </dl>
        
        <div className="gallery mt-20">
            {shipGallery.map((src, index) => (
              <LazyLoadImage key={index}
                  src={src}
                  className="bg-gray-100 rounded-lg"
                  effect="blur"
                  onClick={() => openImageViewer(index)}
                />
            ))}
        </div>
        {isViewerOpen && (
          <ImageViewer
            src={shipGallery}
            currentIndex={currentImage}
            onClose={closeImageViewer}
            disableScroll={true}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)"
            }}
            closeOnClickOutside={true}
          />
        )}

        <Link to="/" className="text-primary flex my-16">Return To App</Link>

      </div>
    </div>
    </>
  )
}
